// useLocalStorage.js

export function getFromStorage(key) {
    if (localStorage.getItem(key)) {
       return localStorage.getItem(key);
    } else {
        return false;
    }
    
}

export function putToStorage(key, value) {
    localStorage.setItem(key, value);
}